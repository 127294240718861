/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';
import {
  filter,
  flatMap,
  get,
  includes,
  isEmpty,
  isNil,
  keyBy,
  map,
  mapValues,
  omitBy,
  some,
  sortBy,
  uniq,
  values,
} from 'lodash-es';
import type {
  EquipmentRecommendationAPIPayload,
  FieldErrors,
  Financing,
  FinancingEvaluationResult,
  FormFields,
  FormFieldsTouched,
  FormValidationResult,
  HideInputsContentOverrideValues,
  HudAmiForHouseholdSize,
  IQSettings,
  LayoutTypeContentOverrideValues,
  Rebate,
  RebatesByRecommendationId,
} from '../typedefs';
import { DEFAULT_HOUSEHOLD_SIZE, STEP_NAMES } from '../utils/constants';
import DifferentiatorUtil from '../utils/differentiatorUtil';
import {
  DuctedDeliveryType,
  FurnaceType,
  LayoutType,
  NumStoriesType,
  PaymentOptionType,
  REBATE_INCOME_QUALIFICATION_CRITERIA_FOR_HUD_AMI_KEYS,
  RebateIncomeQualificationCriteria,
  ServiceType,
  YesNoType,
} from '../utils/enums';
import LoanUtil from '../utils/loanUtil';
import ModelUtil from '../utils/modelUtil';
import NumberUtil from '../utils/numberUtil';
import PartnerUtil from '../utils/partnerUtil';
import PriceUtil from '../utils/priceUtil';
import RebateUtil from '../utils/rebateUtil';
import SizingModeUtil from '../utils/sizingModeUtil';
import StepUtil from '../utils/stepUtil';
import StringUtil from '../utils/stringUtil';
import FormValidator from '../utils/validators/formValidator';
import PropertyDataValidator from '../utils/validators/propertyDataValidator';
import RebateValidator from '../utils/validators/rebateValidator';
import ServiceFormValidator from '../utils/validators/serviceFormValidator';
import type { RootState } from './slice';
import { getServerFieldErrors } from './slice/errors';
import { getInstallerAndNoInstallerFinancing } from './slice/financing';
import { getEffectiveLayoutType, getFormData, getLayoutType, getServiceType } from './slice/formData';
import {
  getHudAmi,
  getNeighborhoodEligibleForIncomeBracketedRebates,
  getNeighborhoodPossibleIncomeQualificationCriteria,
} from './slice/neighborhood';
import {
  getNumUnits,
  getProjectId,
  getProjectInstallerId,
  getProjectSystemConfigurationId,
  getRecommendedACs,
  getRecommendedFurnaces,
  getRecommendedHeatAndCoolProjects,
  getRecommendedProjects,
  getSelectedProject,
  getSelectedProjectId,
} from './slice/projects';
import {
  getAllIncomeQualificationCriteria,
  getUnfilteredRebatesByRecommendationId,
  hasAnyIncomeBracketedRebatesInRecommendations,
} from './slice/rebates';
import {
  getAppSettings,
  getForceExternalUnitLocationVisibility,
  getIQSettings,
  getPartnerEligibleForIncomeBracketedRebates,
  getPartnerOffersPackagedSystems,
  getPartnerPossibleIncomeQualificationCriteria,
  getPartnerSettings,
  getPartnerSlug,
  getShowContactFormForOverride,
  getStepHideInputs,
} from './slice/ui';

const getServerErrorsForTouchedFields = (serverFieldErrors: FieldErrors, touched: FormFieldsTouched): FieldErrors => {
  const errors = mapValues(touched, (value, key) => serverFieldErrors[key]);
  return omitBy(errors, isEmpty);
};

export const getFormValidator = (
  formData: FormFields,
  touched: FormFieldsTouched,
  serviceType?: ServiceType | undefined,
  layoutType?: LayoutType | undefined,
): FormValidator => {
  // NOTE: If "touched" is passed into validate(), then this function could be turned into a selector,
  // and callers could be much simplified. A future change, perhaps...
  if (serviceType === ServiceType.REPAIR || layoutType === LayoutType.BOILER) {
    return new ServiceFormValidator(formData, touched, layoutType);
  }
  return new FormValidator(formData, touched, layoutType);
};

function validateFormForTouchedFields(
  formData: FormFields,
  touched: FormFieldsTouched,
  allServerFieldErrors: FieldErrors,
  serviceType?: ServiceType | undefined,
  layoutType?: LayoutType | undefined,
): FormValidationResult {
  const validator = getFormValidator(formData, touched, serviceType, layoutType);
  validator.validate();

  const { errors } = validator;
  const { generalErrors, fieldErrors } = errors;
  const serverFieldErrors = getServerErrorsForTouchedFields(allServerFieldErrors, touched);
  const hasErrors = validator.hasErrors() || !isEmpty(serverFieldErrors);

  return {
    errors: {
      generalErrors,
      fieldErrors,
      serverFieldErrors,
    },
    hasErrors,
  };
}

export const shouldHideInput = (
  stepHideInputs: HideInputsContentOverrideValues[] | undefined,
  inputName: HideInputsContentOverrideValues,
) => {
  return includes(stepHideInputs, inputName);
};

function determineTouchedForIntroPage(appSettings: IQSettings) {
  if (appSettings.showPhoneInputOnIntro) {
    return {
      layoutType: true,
      serviceType: true,
      firstName: true,
      lastName: true,
      phone: true,
    };
  }
  if (appSettings.showEmailInputOnIntro) {
    return {
      layoutType: true,
      serviceType: true,
      firstName: true,
      lastName: true,
      email: true,
    };
  }
  return { layoutType: true, serviceType: true };
}

export const showHouseholdStep = createSelector(
  [
    getPartnerEligibleForIncomeBracketedRebates,
    getNeighborhoodEligibleForIncomeBracketedRebates,
    hasAnyIncomeBracketedRebatesInRecommendations,
  ],
  (
    isPartnerEligibleForIncomeBracketedRebates,
    isNeighborhoodEligibleForIncomeBracketedRebates,
    areRecommendationsEligibleForIncomeBracketedRebates,
  ): boolean => {
    return (
      isPartnerEligibleForIncomeBracketedRebates ||
      isNeighborhoodEligibleForIncomeBracketedRebates ||
      areRecommendationsEligibleForIncomeBracketedRebates
    );
  },
);

export const validateIntro = createSelector(
  [getFormData, getServiceType, getLayoutType, getServerFieldErrors, getAppSettings],
  (formData, serviceType, layoutType, allServerFieldErrors, appSettings): FormValidationResult => {
    const touched = determineTouchedForIntroPage(appSettings);
    return validateFormForTouchedFields(formData, touched, allServerFieldErrors, serviceType, layoutType);
  },
);

export const validateAddress = createSelector(
  [getFormData, getServiceType, getLayoutType, getServerFieldErrors],
  (formData, serviceType, layoutType, allServerFieldErrors): FormValidationResult => {
    return validateFormForTouchedFields(
      formData,
      {
        zipCode: true,
        streetAddress: true,
      },
      allServerFieldErrors,
      serviceType,
      layoutType,
    );
  },
);

export const validateAddressAndHomeData = createSelector(
  [getFormData, getServiceType, getLayoutType, getServerFieldErrors],
  (formData, serviceType, layoutType, allServerFieldErrors): FormValidationResult => {
    const touched = {
      streetAddress: true,
      zipCode: true,
      sqFt: true,
      yearBuilt: true,
    };
    return validateFormForTouchedFields(formData, touched, allServerFieldErrors, serviceType, layoutType);
  },
);

export const showExternalUnitLocationSelector = createSelector(
  [getPartnerOffersPackagedSystems, getFormData, getForceExternalUnitLocationVisibility],
  (offersPackagedSystems, formData, forceExternalUnitLocationVisibility) => {
    if (formData.ductedDelivery !== DuctedDeliveryType.YES) return false;
    return forceExternalUnitLocationVisibility ?? offersPackagedSystems;
  },
);

export const validateHomeData = createSelector(
  [getFormData, getServiceType, getLayoutType, getServerFieldErrors, showExternalUnitLocationSelector],
  (formData, serviceType, layoutType, allServerFieldErrors, showExternalUnitLocation) => {
    const touched = {
      ductedDelivery: true,
      externalUnitLocation: showExternalUnitLocation,
    };
    return validateFormForTouchedFields(formData, touched, allServerFieldErrors, serviceType, layoutType);
  },
);

export const showTopFloors = createSelector([getFormData], (formData) => {
  const { numStories, accessibleAttic } = formData;
  return numStories && numStories !== NumStoriesType.ONE && accessibleAttic === YesNoType.YES;
});

export const validateMultiSplitRoomData = createSelector(
  [getFormData, getServiceType, getLayoutType, showTopFloors, getServerFieldErrors],
  (formData, serviceType, layoutType, showTopFloors, allServerFieldErrors): FormValidationResult => {
    const touched = {
      totalSmall: true,
      upperSmall: showTopFloors,
      totalMedium: true,
      upperMedium: showTopFloors,
      totalLarge: true,
      upperLarge: showTopFloors,
      totalXLarge: true,
      upperXLarge: showTopFloors,
    };
    return validateFormForTouchedFields(formData, touched, allServerFieldErrors, serviceType, layoutType);
  },
);

const getStepHideInputsForHVACData = (state: RootState) => getStepHideInputs(state, STEP_NAMES.ENERGY_INFO);

export const validateHVACData = createSelector(
  [getFormData, getServiceType, getLayoutType, getServerFieldErrors, getStepHideInputsForHVACData],
  (formData, serviceType, layoutType, allServerFieldErrors, stepHideInputs): FormValidationResult => {
    const touched = {
      furnaceType: !shouldHideInput(stepHideInputs, 'furnaceType'),
      wholeHomeSystem: !shouldHideInput(stepHideInputs, 'wholeHomeSystem'),
    };
    return validateFormForTouchedFields(formData, touched, allServerFieldErrors, serviceType, layoutType);
  },
);

export const validateSingleRoomData = createSelector(
  [getFormData, getServiceType, getLayoutType, getServerFieldErrors],
  (formData, serviceType, layoutType, allServerFieldErrors): FormValidationResult => {
    const touched = { roomSqFt: true };
    return validateFormForTouchedFields(formData, touched, allServerFieldErrors, serviceType, layoutType);
  },
);

export const validateHouseholdData = createSelector(
  [getFormData, getServiceType, getLayoutType, getServerFieldErrors, showHouseholdStep],
  (formData, serviceType, layoutType, allServerFieldErrors, showHouseholdStep): FormValidationResult => {
    const touched = {
      householdSize: !!showHouseholdStep,
    };
    return validateFormForTouchedFields(formData, touched, allServerFieldErrors, serviceType, layoutType);
  },
);

export const validateQuoteContactInfo = createSelector(
  [getFormData, getServiceType, getServerFieldErrors],
  (formData, serviceType, allServerFieldErrors): FormValidationResult => {
    const touched = {
      firstName: true,
      lastName: true,
      email: true,
      phone: true,
    };
    return validateFormForTouchedFields(formData, touched, allServerFieldErrors, serviceType);
  },
);

export const validateContactForm = createSelector(
  [getFormData, getServiceType, getServerFieldErrors],
  (formData, serviceType, allServerFieldErrors): FormValidationResult => {
    const touched = {
      firstName: true,
      lastName: true,
      email: true,
      phone: true,
      repairDetails: true,
    };
    return validateFormForTouchedFields(formData, touched, allServerFieldErrors, serviceType);
  },
);

export const hasRebateErrors = createSelector([getFormData], (formData) => {
  const validator = new RebateValidator(formData);
  validator.validate();
  return validator.hasErrors();
});

export const hasPropertyDataErrors = createSelector([getFormData], (formData) => {
  const validator = new PropertyDataValidator(formData);
  validator.validate();
  return validator.hasErrors();
});

export const showWholeHomeSelector = createSelector([getFormData], (formData) => {
  const { layoutType, ductedDelivery, furnaceType } = formData;
  return (
    (layoutType === LayoutType.WHOLE_HOME || layoutType === LayoutType.REPLACE_EXISTING) &&
    ductedDelivery !== DuctedDeliveryType.NO &&
    furnaceType !== FurnaceType.ELECTRIC_HEAT_PUMP
  );
});

export const showNumExistingSystemsSelector = createSelector(
  [getFormData],
  SizingModeUtil.showNumExistingSystemsSelector,
);

export const showExistingAcTonnageSelector = createSelector(
  [getFormData],
  SizingModeUtil.showExistingAcTonnageSelector,
);

export const hasFinancingErrors = hasRebateErrors;

export const getSortedProjectList = createSelector([getRecommendedProjects], (recommendations) => {
  const projects = recommendations.slice();
  projects.sort((a, b) => {
    return (
      (b.productTier || ModelUtil.getHeatPumpDesignType(b)) - (a.productTier || ModelUtil.getHeatPumpDesignType(a))
    );
  });
  return projects;
});

export const getSortedFurnaceList = createSelector([getRecommendedFurnaces], (recommendations) => {
  const projects = recommendations.slice();
  projects.sort((a, b) => {
    return b.productTier - a.productTier;
  });
  return projects;
});

export const getSortedACList = createSelector([getRecommendedACs], (recommendations) => {
  const projects = recommendations.slice();
  projects.sort((a, b) => {
    return (
      (b.productTier || ModelUtil.getHeatPumpDesignType(b)) - (a.productTier || ModelUtil.getHeatPumpDesignType(a))
    );
  });
  return projects;
});

export const getSortedHeatAndCoolList = createSelector([getRecommendedHeatAndCoolProjects], (recommendations) => {
  const projects = recommendations.slice();
  projects.sort((a, b) => {
    return (
      (b.productTier || ModelUtil.getHeatPumpDesignType(b)) - (a.productTier || ModelUtil.getHeatPumpDesignType(a))
    );
  });
  return projects;
});

export const getRecommendedProject = createSelector([getSortedProjectList], (projectList) => {
  if (isEmpty(projectList)) {
    return undefined;
  }
  const sortedList = sortBy(
    projectList,
    (project) => project.carbonSavings || 0,
    (project) => project.productTier || 0,
  ).reverse();
  return sortedList[0];
});

export const getSummaryProject = createSelector(
  [getSelectedProject, getRecommendedProject],
  (selected, recommended) => {
    return selected ?? recommended;
  },
);

// This is extracted from logic that was using getSummaryProject().
// Should this be changed to use getProjectList() or getRecommendedProject()? Why is getSelectedProject() useful?
export const hasRecommendations = createSelector([getSummaryProject], (summary): boolean => {
  return !!summary;
});

export const recommendedProjectsHaveMultipleUnits = createSelector([getSortedProjectList], (projectList) => {
  return some(projectList, (project) => {
    const numUnits = getNumUnits(project);
    return numUnits && numUnits > 1;
  });
});

export const isInReplacementMode = createSelector([getFormData], SizingModeUtil.isInReplacementMode);

export const replacementSearchResultsHaveMultipleUnits = createSelector(
  [isInReplacementMode, recommendedProjectsHaveMultipleUnits],
  (isInReplacementMode: boolean, recommendedProjectsHaveMultipleUnits: boolean) => {
    return isInReplacementMode && recommendedProjectsHaveMultipleUnits;
  },
);

export const getSummaryProjectId = createSelector(
  [getSelectedProjectId, getSummaryProject],
  (selectedProjectId, summaryProject) => {
    return selectedProjectId ?? getProjectId(summaryProject);
  },
);

export const getCompletionUrl = createSelector(
  [getSummaryProject, getPartnerSettings, getPartnerSlug, getFormData],
  PartnerUtil.getProjectCompletionUrl,
);

export const getProjectIds = createSelector([getSortedProjectList], (projects) => {
  const projectIds = projects
    .map((project: EquipmentRecommendationAPIPayload) => {
      return getProjectId(project);
    })
    .filter((id) => !!id);
  return projectIds as number[];
});

export const getProjectSystemIds = createSelector([getSortedProjectList], (projects) => {
  const projectIds = projects
    .map((project: EquipmentRecommendationAPIPayload) => {
      return getProjectSystemConfigurationId(project);
    })
    .filter((id) => !!id);
  return projectIds as string[];
});

export const getRebatesByProjectId = createSelector(
  [getUnfilteredRebatesByRecommendationId, getFormData],
  (
    rebatesByProjectId: { [recommendationId: string]: Rebate[] | undefined },
    formData: FormFields,
  ): RebatesByRecommendationId => {
    return mapValues(rebatesByProjectId, (projectRebates) => {
      return RebateUtil.filterRebatesForProject(projectRebates, formData);
    });
  },
);

export const getProjectRebates = (store: RootState, project: EquipmentRecommendationAPIPayload) => {
  const rebatesByProjectId = getRebatesByProjectId(store);
  const projectRebates = rebatesByProjectId[project.recommendationId];
  return projectRebates || [];
};

export const getLargestIncomeBracketedRebate = createSelector([getRebatesByProjectId], (rebatesByProjectId) => {
  const allIncomeBracketedRebates = flatMap(values(rebatesByProjectId), (rebates) => {
    const incomeBracketedRebates = filter(rebates, (rebate) => !isEmpty(rebate.incomeQualificationCriteria));
    return incomeBracketedRebates;
  });

  return RebateUtil.pickLargest(uniq(filter(allIncomeBracketedRebates)));
});

export const getShowContactFormForOverrideWithBoiler = createSelector(
  [getShowContactFormForOverride],
  (showContactFormForOverride): LayoutTypeContentOverrideValues[] => {
    if (isEmpty(showContactFormForOverride)) return ['BOILER'];
    const withBoiler = uniq(filter([...showContactFormForOverride, 'BOILER']));
    return withBoiler as LayoutTypeContentOverrideValues[];
  },
);

export const showContactForm = createSelector(
  [getLayoutType, getShowContactFormForOverrideWithBoiler],
  (layoutType, showContactFormFor): boolean => {
    if (layoutType === undefined) return false;
    return includes(showContactFormFor, StringUtil.convertEnumValueToString(layoutType, LayoutType));
  },
);

export const getStepContext = createSelector(
  [getPartnerSlug, getEffectiveLayoutType, showHouseholdStep, showContactForm],
  (partnerSlug, layoutType, includeHouseholdStep, showContactForm) => {
    return {
      partnerSlug,
      layoutType,
      includeHouseholdStep,
      showContactForm,
    };
  },
);

export const getSteps = createSelector(
  [getEffectiveLayoutType, showHouseholdStep, showContactForm],
  (layoutType, includeHouseholdStep, showContactForm) => {
    return StepUtil.getSteps(layoutType, includeHouseholdStep, showContactForm);
  },
);

export const getTotalSteps = createSelector([getSteps], (steps) => {
  return steps.length;
});

export const getPath = createSelector([getLayoutType], (layoutType) => StepUtil.getPath(layoutType));

export const getLabelsByProjectId = createSelector([getSortedProjectList], DifferentiatorUtil.getLabelsByProjectId);

export const getRecommendedProjectInstallerIds = createSelector(
  [getRecommendedProjects],
  (projects: EquipmentRecommendationAPIPayload[]) => {
    const installerIds = map(projects, getProjectInstallerId);
    const uniqIds = uniq(filter(installerIds));
    return uniqIds;
  },
);

export const getShowPhoneInputOnIntro = createSelector([getServiceType, getAppSettings], (serviceType, appSettings) => {
  const { showPhoneInputOnIntro } = appSettings;
  return serviceType !== ServiceType.REPAIR ? showPhoneInputOnIntro : false;
});

export const getShowEmailInputOnIntro = createSelector([getServiceType, getAppSettings], (serviceType, appSettings) => {
  const { showEmailInputOnIntro } = appSettings;
  return serviceType !== ServiceType.REPAIR ? showEmailInputOnIntro : false;
});

export const getShowRepairFormOnIntro = createSelector([getAppSettings], (appSettings) => {
  return appSettings?.showRepairFormOnIntro === true;
});

export const getBestFinancingEvaluationByProjectId = createSelector(
  [getRecommendedProjects, getInstallerAndNoInstallerFinancing, getRebatesByProjectId],
  (
    projects: EquipmentRecommendationAPIPayload[],
    installerAndNoInstallerFinancing: {
      noInstallerFinancing: Financing[];
      installerFinancing: { [installerId: number]: Financing[] };
    },
    rebatesByProjectId: RebatesByRecommendationId,
  ): { [recommendationId: string]: FinancingEvaluationResult | undefined } => {
    if (isEmpty(projects)) return {};

    const projectsByProjectId = keyBy(projects, (project) => NumberUtil.toString(project.recommendationId) as string);
    const financingByInstallerId = installerAndNoInstallerFinancing.installerFinancing;

    const results = mapValues(projectsByProjectId, (project, projectId) => {
      if (isEmpty(financingByInstallerId)) return;

      const installerId = getProjectInstallerId(project);
      const rebates = rebatesByProjectId[projectId];
      const financingOptions = financingByInstallerId[installerId];

      if (isEmpty(financingOptions)) return;

      const { priceAfterRebates, monthlyUtilitySavings } = PriceUtil.evaluatePricingForProject({ project, rebates });

      const evaluationResult = LoanUtil.pickBestFinancingForProject(
        financingOptions,
        priceAfterRebates,
        monthlyUtilitySavings,
      );

      return evaluationResult;
    });

    const filteredResults = omitBy(results, isNil);
    return filteredResults;
  },
);

export const getBestProjectFinancingEvaluation = (
  store: RootState,
  project: EquipmentRecommendationAPIPayload,
): FinancingEvaluationResult | undefined => {
  const financingByProjectId = getBestFinancingEvaluationByProjectId(store);
  const projectFinancing = financingByProjectId[project.recommendationId];
  return projectFinancing;
};

export const isCashSelected = createSelector([getIQSettings, getFormData], (iqSettings, formData) => {
  const { paymentOptionType } = formData;
  const { showFinancingByDefault } = iqSettings;
  return paymentOptionType === undefined ? !showFinancingByDefault : paymentOptionType === PaymentOptionType.CASH;
});

export const getHudAmiForHouseholdSize = createSelector(
  [getHudAmi, getFormData],
  (hudAmi, formData): HudAmiForHouseholdSize | undefined => {
    if (isEmpty(hudAmi) || isEmpty(formData)) return undefined;

    const householdSize = formData.householdSize ?? DEFAULT_HOUSEHOLD_SIZE;
    const householdSizeString = `${householdSize}`;

    const filteredHudAmi = mapValues(hudAmi, (hudAmiHousehold) => {
      return get(hudAmiHousehold, householdSizeString) as number | undefined;
    });

    const hudAmiWithoutUndefined = omitBy(filteredHudAmi, isNil);
    if (isEmpty(hudAmiWithoutUndefined)) return undefined;

    return hudAmiWithoutUndefined as HudAmiForHouseholdSize;
  },
);

const getRebateIncomeQualificationCriteriaForHudAmiKey = (
  hudAmiKey: keyof HudAmiForHouseholdSize,
): RebateIncomeQualificationCriteria | undefined => {
  return REBATE_INCOME_QUALIFICATION_CRITERIA_FOR_HUD_AMI_KEYS[hudAmiKey];
};

export const getIncomeQualificationCriteria = createSelector(
  [
    getPartnerPossibleIncomeQualificationCriteria,
    getNeighborhoodPossibleIncomeQualificationCriteria,
    getAllIncomeQualificationCriteria,
  ],
  (
    partnerIncomeQualificationCriteria,
    neighborhoodIncomeQualificationCriteria,
    recommendationsIncomeQualificationCriteria,
  ): RebateIncomeQualificationCriteria[] => {
    const combinedIncomeQualificationCriteria = [
      ...partnerIncomeQualificationCriteria,
      ...neighborhoodIncomeQualificationCriteria,
      ...recommendationsIncomeQualificationCriteria,
    ];
    const incomeQualificationCriteria = filter(uniq(combinedIncomeQualificationCriteria));
    return incomeQualificationCriteria;
  },
);

export const getHudAmiForRebatesAndHouseholdSize = createSelector(
  [getHudAmiForHouseholdSize, getIncomeQualificationCriteria],
  (hudAmiForHouseholdSize, incomeQualificationCriteria): HudAmiForHouseholdSize | undefined => {
    if (!hudAmiForHouseholdSize || isEmpty(hudAmiForHouseholdSize) || isEmpty(incomeQualificationCriteria))
      return undefined;

    const filteredHudAmi = omitBy(hudAmiForHouseholdSize, (value: number | undefined, key: string) => {
      if (!value) return true;
      const iqCriterion = getRebateIncomeQualificationCriteriaForHudAmiKey(key as any);
      return !iqCriterion || !includes(incomeQualificationCriteria, iqCriterion);
    });
    if (isEmpty(filteredHudAmi)) return undefined;

    return filteredHudAmi;
  },
);
